import Vue from 'vue'
import App from './App'
import store from './store/store'
import router from './router'
import VeeValidate, { Validator } from 'vee-validate'
import VueGtm from '@gtm-support/vue2-gtm'
import { getLocale, i18n, messages } from './locales/helper'
import BootstrapVue from 'bootstrap-vue'
import VueScrollTo from 'vue-scrollto'
import Toasted from 'vue-toasted'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

// you can also pass options, check options reference below
import $ from 'jquery'

import {
  validEmailForEvent,
  isMultipleOf,
  isMultipleOfParamNames,
  containsNoDigits,
} from './helpers/customValidation'

import env from '@/config/env'

Validator.extend('validEmailForEvent', validEmailForEvent(store, i18n))

Validator.extend('isMultipleOf', isMultipleOf(store, i18n), {
  paramNames: isMultipleOfParamNames,
})

Validator.extend('no_digits', containsNoDigits(i18n))

Vue.use(Toasted, {
  keepOnHover: true,
  duration: 7000,
  position: 'top-center',
  fullWidth: false,
  fitToScreen: true,
})

Sentry.init({
  Vue,
  dsn: 'https://6ccff6ae12bb43abbf904509f99fc720@o4504020351778816.ingest.sentry.io/4504682990338048',
  environment: env || 'development',
  enabled: ['production', 'staging'].includes(env),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        /^https:\/\/ye-olde-ticketshop123.omr.com/,
        /^https:\/\/ye-olde-ticketshop123.omr.ninja/,
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
})

// Lets Register a Global Error Notification Toast.
Vue.toasted.register(
  'customSuccess',
  (message) => {
    return message
  },
  {
    type: 'success',
    className: 'custom-success-class',
  },
)

Vue.use(VueGtm, { id: 'GTM-N34CWC', vueRouter: router })
Vue.use(BootstrapVue)
Vue.use(VueScrollTo)

const locale = getLocale()
const localizedValidations = require(`vee-validate/dist/locale/${locale}`)
const localizedAttributes = messages[locale].validations.attributes

Vue.use(VeeValidate)

Validator.localize(locale, {
  ...localizedValidations,
  attributes: localizedAttributes,
})

Vue.config.productionTip = false

window.$ = $

new Vue({
  el: '#app',
  store,
  i18n: i18n,
  beforeCreate() {
    this.$store.commit('initialiseStore')
  },
  router: router,
  render: (h) => h(App),
})
